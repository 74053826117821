import React from "react"
import { DialogModal, DialogModalTypes } from "@fiken/components"

import { useDispatch } from "react-redux"
import { SelectedKontoParam } from "./SelectedKonto"
import { generatePath, useNavigate, useParams } from "react-router"
import { RoutesName } from "../../app/RoutesName"
import KontoInfoView from "./KontoInfoView"
import { resetSelectedKonto } from "../konto/kontoSlice"

export default function SelectedKontoDialog({ konto }: SelectedKontoParam): React.ReactElement {
  const params = useParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const onclose = (): void => {
    dispatch(resetSelectedKonto())
    const { ORG_FORM_MED_MOMS } = RoutesName
    navigate(`/${generatePath(ORG_FORM_MED_MOMS, { ...params })}`)
  }

  return (
    <DialogModal
      type={DialogModalTypes.visualMedia}
      onclose={onclose}
      render={(): React.ReactElement => {
        if (konto === null) return <></>
        return <KontoInfoView konto={konto} />
      }}
    />
  )
}
