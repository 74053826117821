import React, { useEffect, useMemo, useState } from "react"
import Searchbar from "./Searchbar"
import Fuse, { FuseResultMatch } from "fuse.js"
import { KontogruppeInfo, KontoInfo } from "../../app/apiSlice"
import { fuseOptions } from "./fuseOptions"
import { useSearchParams } from "react-router-dom"
import { isNumber } from "@fiken/components"
import { utenStoppord } from "./stopord"
import { allKeys } from "./allKeys"
import { KontoForFuse } from "../konto/Kontoer"

type SearchProps = {
  kontoForFuse: KontoForFuse
  reset: () => void
  onSearch: (kontogruppe: KontogruppeInfo[]) => void
}

export default function Search({ onSearch, reset, kontoForFuse }: SearchProps): React.ReactElement {
  const kontogruppeInfoFuse = useMemo(() => {
    return new Fuse<KontogruppeInfo>(kontoForFuse.kontogruppeInfo, { ...fuseOptions, keys: allKeys })
  }, [kontoForFuse])

  const [keyword, setSearchKeyword] = useState<string | null>(null)
  const [searchParam, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (keyword !== null && keyword.trim().length > 0) {
      onChange(keyword.trim())
    }
  }, [kontogruppeInfoFuse])

  useEffect(() => {
    if (keyword === null) return
    if (keyword.trim().length > 0) {
      setSearchParams({ sok: keyword })
    } else {
      setSearchParams({})
    }

    const timeout = setTimeout(() => {
      onChange(keyword.trim())
    }, 200)

    return () => clearTimeout(timeout)
  }, [keyword])

  useEffect(() => {
    const search = searchParam.get("sok") ?? ""
    onChange(search.trim())
  }, [searchParam])

  const buildKeywords = (value: string): string => {
    if (isNumber(value)) {
      return `^${value} | '" ${value}"`
    } else if (/\d\s%/.test(value)) {
      return `'"${value}"`
    } else if (/\d%/.test(value)) {
      return `'"${value.replace("%", " %")}"`
    } else {
      return `'${utenStoppord(value.split(" ")).join(" ")}`
    }
  }

  const findResult = (fuse: Fuse<KontogruppeInfo>, value: string): KontogruppeInfo[] => {
    const keyword = buildKeywords(value.replaceAll(/\s{2,}/g, " "))
    return fuse.search(keyword).map(({ item, matches = [] }) => {
      const hasRefIndex = matches.every((match: FuseResultMatch) => match.refIndex !== undefined)
      if (hasRefIndex) {
        const unique = [...new Set(matches.map((match: FuseResultMatch) => match.refIndex))]
        return {
          ...item,
          kontoer: item.kontoer.filter(
            (konto: KontoInfo, index: number) => unique.includes(index) || JSON.stringify(konto).includes(value),
          ),
        }
      }
      return {
        ...item,
        kontoer: item.kontoer.filter((konto: KontoInfo) => JSON.stringify(konto).includes(value)),
      }
    })
  }

  const onChange = (value: string): void => {
    if (kontogruppeInfoFuse !== undefined) {
      if (value.length > 0) {
        onSearch(findResult(kontogruppeInfoFuse, value))
      } else {
        reset()
      }
    }
  }

  return <Searchbar onChange={setSearchKeyword} value={searchParam.get("sok") ?? ""} />
}
