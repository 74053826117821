import React from "react"
import VelgFraListen from "./VelgFraListen"
import { Column, ColumnMDTypes, ColumnXSTypes } from "@fiken/components"
import { SelectedKontoParam } from "./SelectedKonto"
import KontoInfoView from "./KontoInfoView"
import Card from "../shared/Card"

const SelectedKontoContent = ({ konto }: SelectedKontoParam): React.ReactElement => {
  if (konto === null) {
    return <VelgFraListen />
  }

  return <KontoInfoView konto={konto} />
}

export default function SelectedKontoColumn({ konto }: SelectedKontoParam): React.ReactElement {
  return (
    <Column types={[ColumnXSTypes.TWELVE, ColumnMDTypes.SIX]}>
      <Card>
        <SelectedKontoContent konto={konto} />
      </Card>
    </Column>
  )
}
