import React, { useEffect } from "react"
import { Column } from "@fiken/components"
import Styles from "./Filter.module.css"
import OrgVelger from "./OrgVelger"
import MomsregistrertLenke from "./MomsregistrertLenke"
import { BaseViewProp } from "../../types/type"
import { useParams } from "react-router"
import { storeParams } from "../konto/paramsStorage"

type FilterProps = BaseViewProp

export default function Filter({ children }: FilterProps): React.ReactElement {
  const params = useParams()
  useEffect(() => {
    storeParams(params)
  }, [params])

  return (
    <>
      <Column className={Styles.filter}>
        <OrgVelger />
        <MomsregistrertLenke />
      </Column>
      <Column className={Styles.search}>{children}</Column>
    </>
  )
}
