import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
export type KontoMetaDataInfo = {
  readonly hjelpetekst?: string
  readonly advarsel?: string
  readonly eksempler?: string
  readonly gyldigeMvakoder: string[]
  readonly defaultMvakode: string
  readonly defaultMvakodeErIngen: boolean
  readonly labels: string[]
  readonly statusfarge?: string
  readonly sokeord: string[]
  readonly hjelpetekstForFuse?: string
  readonly sokeordForFuse: string
}

export type KontoInfo = {
  readonly kontonummer: number
  readonly navn: string
  readonly kunForOrgForm: string[]
  readonly metaData: KontoMetaDataInfo
}

export type KontogruppeInfo = {
  readonly navn: string
  readonly nummer: number
  readonly kontoer: KontoInfo[]
}

const { DEV } = import.meta.env
const baseUrl: string = DEV ? "/base" : ""

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/data`,
  }),
  endpoints: (build) => ({
    fetchKontogrupper: build.query<KontogruppeInfo[], void>({
      query: () => "/kontoGruppeInfo",
      transformResponse: (returnValue: KontogruppeInfo[]) => {
        return returnValue.map(({ kontoer, ...gruppeProps }) => {
          const konto: KontoInfo[] = kontoer.map(({ metaData, ...props }) => {
            const sokeordForFuse = `${metaData.sokeord.join(" ")} ${props.navn.toLowerCase()}`
            return {
              ...props,
              metaData: {
                ...metaData,
                hjelpetekstForFuse: metaData.hjelpetekst?.replace(/\[(.*)]\(.*\)/, "$1"),
                sokeordForFuse,
              },
            }
          })
          return { ...gruppeProps, kontoer: konto }
        })
      },
    }),
  }),
})

export const { useFetchKontogrupperQuery } = apiSlice
