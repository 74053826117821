import React from "react"
import { useAppSelector } from "../../app/hooks"
import { Head, MetaProps } from "@fiken/components"

export default function KontoHead(): React.ReactElement {
  const { selectedKonto } = useAppSelector((state) => state.konto)

  const metaProps = ((): MetaProps => {
    if (selectedKonto !== null) {
      const { navn, kontonummer } = selectedKonto
      return {
        title: `${kontonummer} - ${navn}`,
        description: `${navn}`,
      }
    }

    return {
      title: "Gratis kontohjelp – Finn riktig konto til regnskapet – Fiken",
      description:
        "Søk på helt vanlige ord – som kontorstol eller macbook – og finn riktig konto det skal føres på i regnskapet.",
    }
  })()

  return <Head {...metaProps} />
}
