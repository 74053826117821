import Main from "../main/Main"
import Info from "../info/Info"
import React from "react"
import { BaseViewProp } from "../../types/type"
import { useAppSelector } from "../../app/hooks"
import { FikenPromoCurvedContainer, Footer, FooterBanner, Header, ScrollToTop } from "@fiken/components"
import { setting } from "../../app/setting"

const FikenPromoContainer = (): React.ReactElement => {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth)
  if (!isAuthenticated) {
    return (
      <div style={{ background: "var(--fiken-color-blue-300)" }}>
        <FikenPromoCurvedContainer background={"var(--fiken-color-white-500)"} />
      </div>
    )
  }
  return <></>
}

export default function Layout({ children }: BaseViewProp): React.ReactElement {
  const { isAuthenticated } = useAppSelector(({ auth }) => auth)
  const { viewportWidth } = useAppSelector((state) => state.setting)
  const isDesktop = viewportWidth > setting.breakpoints.large
  const visLogoMedNavn = viewportWidth > setting.breakpoints.xSmall

  return (
    <>
      <Header visLogoMedNavn={visLogoMedNavn} visFikenMoto={isDesktop} isAuthenticated={isAuthenticated} />
      <Main />
      <div
        style={{
          minHeight: "700px",
          background: "var(--fiken-color-blue-300)",
        }}
      >
        {children}
      </div>
      <Info />
      <FikenPromoContainer />
      <Footer />
      <FooterBanner />
      <ScrollToTop />
    </>
  )
}
