import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { apiSlice, KontoInfo } from "../../app/apiSlice"

export type Kontonumre = {
  nummer: number
  kontoer: number[]
}

export type SelectedKontoState = {
  kontonumre: Kontonumre[]
  selectedKonto: KontoInfo | null
}

const initialState: SelectedKontoState = Object.freeze({
  kontonumre: [],
  selectedKonto: null,
})

const kontoSlice = createSlice({
  name: "konto",
  initialState,
  reducers: {
    setSelectedKonto(state, { payload }: PayloadAction<KontoInfo | null>) {
      return { ...state, selectedKonto: payload }
    },
    resetSelectedKonto(state) {
      return { ...state, selectedKonto: null }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(apiSlice.endpoints.fetchKontogrupper.matchFulfilled, (state, { payload }) => {
      const kontonumre = (payload ?? []).map(({ kontoer, nummer }) => ({
        nummer,
        kontoer: kontoer.map(({ kontonummer }) => kontonummer),
      }))
      return { ...state, kontonumre }
    })
  },
})

export const { setSelectedKonto, resetSelectedKonto } = kontoSlice.actions
export default kontoSlice.reducer
