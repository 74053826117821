import React, { useEffect, useState } from "react"
import Styles from "./MomsregistrertLenke.module.css"
import { Link } from "react-router-dom"
import { momsregistrert } from "../konto/momsregistrert"
import { useGeneratePath } from "./useGeneratePath"
import { useParams } from "react-router"

export default function MomsregistrertLenke(): React.ReactElement {
  const params = useParams()
  const { medMoms, utenMoms } = momsregistrert
  const [erMedMoms, setErMedMoms] = useState<boolean>(medMoms === params.medMoms)

  useEffect(() => setErMedMoms(medMoms === params.medMoms), [params])

  const moms = erMedMoms ? utenMoms : medMoms
  const to = useGeneratePath({ medMoms: moms }, [erMedMoms])

  return (
    <Link to={to} className={`${Styles.momsRegistrertLenke}`} onClick={() => setErMedMoms(!erMedMoms)}>
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <rect
          x="1"
          y="1"
          width="15"
          height="15"
          rx="3"
          style={{
            stroke: erMedMoms ? "#00997D" : "var(--fiken-color-black-500)",
          }}
          strokeWidth="2"
          strokeLinejoin="round"
        />
        {erMedMoms && (
          <path d="M5 8L8 11L12 5" stroke="#00997D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        )}
      </svg>
      <span>Momsregistrert</span>
    </Link>
  )
}
