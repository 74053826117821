import React from "react"
import Styles from "./KontogruppeList.module.css"
import { KontogruppeInfo } from "../../app/apiSlice"
import { KontogruppeListItem } from "./KontogruppeListItem"
import { useSearchParams } from "react-router-dom"
import IngenTreff from "./IngenTreff"

type KontogruppeListParam = {
  kontogruppeInfo: KontogruppeInfo[]
}

export default function KontogruppeList({ kontogruppeInfo }: KontogruppeListParam): React.ReactElement {
  const [searchParam] = useSearchParams()
  const sok = searchParam.get("sok")

  if (sok !== null && sok.length > 0 && kontogruppeInfo.length === 0) {
    return <IngenTreff sok={sok} />
  }

  return (
    <ul className={Styles.kontogrupper}>
      {kontogruppeInfo.map((info) => (
        <KontogruppeListItem kontogruppeInfo={info} key={info.nummer} />
      ))}
    </ul>
  )
}
