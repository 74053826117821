import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from "./apiSlice"
import settingSlice from "./settingSlice"
import kontoSlice from "../features/konto/kontoSlice"
import authSlice from "./authSlice"
import { whoAmIApi } from "./whoAmIApi"

export const store = configureStore({
  reducer: {
    auth: authSlice,
    setting: settingSlice,
    konto: kontoSlice,
    [whoAmIApi.reducerPath]: whoAmIApi.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware, whoAmIApi.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
