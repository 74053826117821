import React, { useEffect, useRef, useState } from "react"
import { KontoInfo } from "../../app/apiSlice"
import Styles from "./KontoerList.module.css"
import { useParams } from "react-router"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../app/hooks"
import KontoLink from "../shared/KontoLink"
import { setSelectedKonto } from "../konto/kontoSlice"
import SokeordView from "./SokeordView"
import { useSearchParams } from "react-router-dom"

type KontoerListParam = {
  kontoer: KontoInfo[]
}

function KontoerListItem(kontoInfo: KontoInfo): React.ReactElement {
  const { navn, kontonummer, metaData } = kontoInfo
  const params = useParams()
  const [searchParam] = useSearchParams()

  const dispatch = useDispatch()
  const [style, setStyle] = useState<React.CSSProperties>({
    background: "var(--fiken-color-white-500)",
    color: "var(--fiken-color-black-500)",
  })

  const { selectedKonto } = useAppSelector((state) => state.konto)
  const [scrollIntoView, setScrollIntoView] = useState<boolean>(true)

  const kontoRef = useRef<HTMLLIElement>(null)
  const dispatchSelectedKonto = (): void => {
    dispatch(setSelectedKonto(kontoInfo))
    setScrollIntoView(false)
  }

  useEffect(() => {
    if (params.kontonummer !== undefined && Number(params.kontonummer) === kontonummer) {
      if (selectedKonto === null || selectedKonto.kontonummer !== kontoInfo.kontonummer) {
        dispatchSelectedKonto()
      }
      const { current } = kontoRef
      if (current !== null && scrollIntoView) {
        const { offsetParent } = current
        if (offsetParent !== null) {
          const top = current.offsetTop - offsetParent.clientHeight / 2
          offsetParent.scrollTo({ top })
        }
      }
      setScrollIntoView(true)
    }
  }, [params.kontonummer])

  useEffect(() => {
    if (selectedKonto !== null && selectedKonto.kontonummer === kontoInfo.kontonummer) {
      setStyle({
        background: "var(--fiken-color-purple-800)",
        color: "white",
        borderColor: "var(--fiken-color-purple-800)",
      })
    } else {
      setStyle({})
    }
  }, [selectedKonto])

  return (
    <li onClick={dispatchSelectedKonto} ref={kontoRef} style={style}>
      <KontoLink kontonummer={kontonummer} searchParam={searchParam.toString()} preventScrollReset={false}>
        <dl>
          <dt>{kontonummer}</dt>
          <dd>{navn}</dd>
        </dl>
        {metaData.sokeord.length > 0 ? <SokeordView sokeord={metaData.sokeord} /> : null}
      </KontoLink>
    </li>
  )
}

export default function KontoerList({ kontoer }: KontoerListParam): React.ReactElement {
  return (
    <ul className={Styles.kontoerList}>
      {kontoer.map((konto) => (
        <KontoerListItem key={konto.kontonummer} {...konto} />
      ))}
    </ul>
  )
}
