// @ts-expect-error
// prettier-ignore
import { nob as stoppord, removeStopwords } from "stopword/dist/stopword.esm.mjs";

const ekstraStoppord = [
  "hele",
  "heller",
  "beløp",
  "beløpet",
  "helhet",
  "http",
  "https",
  "hjelp",
  "hjelpeartikkel",
  "hjelpeartikkelen",
  "support",
  "solutions",
  "no",
  "articles",
  "as",
  "enk",
  "året",
  "balanseført",
  "balanseføring",
  "bokført",
  "bruk",
  "brukes",
  "bokføre",
  "bokføres",
  "bokføring",
  "brutto",
  "betyr",
  "balanse",
  "balansen",
  "bestemmer",
  "bestemmes",
  "beskattes",
  "beskatning",
  "beregnes",
  "betalt",
  "betale",
  "betaler",
  "betales",
  "beslutte",
  "besluttes",
  "beslutninger",
  "beskrevet",
  "betaling",
  "betalinger",
  "betalingen",
  "beskrivelse",
  "bedrift",
  "bedriften",
  "bedriftens",
  "bedre",
  "bedrifter",
  "c3",
  "b8r",
  "dvs",
  "dermed",
  "differansen",
  "deretter",
  "derfor",
  "driften",
  "del",
  "dager",
  "direkte",
  "dersom",
  "dekke",
  "dekket",
  "dekker",
  "delen",
  "debet",
  "diverse",
  "dels",
  "dls",
  "drive",
  "driver",
  "danner",
  "din",
  "dine",
  "dra",
  "enda",
  "eksempel",
  "føres",
  "foretak",
  "foreslår",
  "forbindelse",
  "frem",
  "føre",
  "få",
  "får",
  "fører",
  "forblir",
  "fleste",
  "finnes",
  "formålet",
  "flere",
  "fordeles",
  "fri",
  "fritt",
  "faller",
  "går",
  "gjelder",
  "gjøres",
  "gjør",
  "gjøre",
  "gå",
  "gjelde",
  "gi",
  "gir",
  "gis",
  "gitt",
  "gjennom",
  "hos",
  "hvert",
  "holde",
  "hovedregel",
  "hovedregelen",
  "håndterer",
  "håndteres",
  "hhv",
  "jf",
  "januar",
  "komme",
  "kommer",
  "kommet",
  "kr",
  "konto",
  "kontoen",
  "kontoene",
  "likevel",
  "lagt",
  "lar",
  "litt",
  "lik",
  "like",
  "må",
  "ment",
  "mottas",
  "mye",
  "menes",
  "mulig",
  "meldes",
  "mer",
  "mottatt",
  "mens",
  "motsatt",
  "motsetning",
  "ny",
  "normalt",
  "nærmere",
  "navnet",
  "nødvendigvis",
  "neste",
  "null",
  "nye",
  "org",
  "oftest",
  "osv",
  "omgjøres",
  "ofte",
  "omfang",
  "oppstår",
  "pga",
  "pålagt",
  "passer",
  "periodisering",
  "per",
  "programmet",
  "registrert",
  "regler",
  "redusere",
  "reduseres",
  "regnes",
  "registrere",
  "registreres",
  "riktig",
  "reelle",
  "regel",
  "selskapet",
  "slike",
  "sjelden",
  "små",
  "sammen",
  "større",
  "står",
  "samt",
  "selve",
  "senere",
  "skylder",
  "sendt",
  "sendes",
  "slutten",
  "serien",
  "sett",
  "skylde",
  "sammenhengen",
  "salget",
  "skilles",
  "sagt",
  "se",
  "støtter",
  "typisk",
  "tidligere",
  "tatt",
  "tror",
  "tvers",
  "to",
  "tilfeller",
  "tar",
  "tas",
  "tjenesten",
  "utgiftsført",
  "under",
  "vesentlige",
  "vanlig",
  "veiviser",
  "www",
  "wikipedia",
  "wiki",
  "xx",
  "xxx",
  "ønsker",
  "øker",
  "øke",
  "økte",
  "økes",
  "økning",
  "ønskes",
  "økt",
  "økonomiske",
]

export const utenStoppord = (ord: string[], ekstra = true, vanlig = true): string[] =>
  removeStopwords(ord, [...(vanlig ? stoppord : []), ...(ekstra ? ekstraStoppord : [])])
