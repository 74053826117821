import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router"
import { getParams, UrlParams } from "./paramsStorage"

export const initialParams = Object.freeze<UrlParams>({
  orgForm: "enk",
  medMoms: "medMoms",
})

export function useCustomParams(): UrlParams {
  const params = useParams()
  const { pathname } = useLocation()
  const urlParams = (getParams() as UrlParams) ?? initialParams

  const [parameter, setParameter] = useState<UrlParams>(urlParams)

  useEffect(() => {
    const { orgForm = urlParams.orgForm, medMoms = urlParams.medMoms } = params
    const o = { orgForm, medMoms }
    setParameter(o)
  }, [params, pathname])

  return parameter
}
