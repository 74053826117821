import React, { memo } from "react"
import { useAppSelector } from "../../app/hooks"
import SelectedKontoDialog from "./SelectedKontoDialog"
import SelectedKontoColumn from "./SelectedKontoColumn"
import { KontoInfo } from "../../app/apiSlice"

export type SelectedKontoParam = {
  konto: KontoInfo | null
}

export const SelectedKonto = memo((): React.ReactElement => {
  const selector = useAppSelector((state) => state)
  const { selectedKonto } = selector.konto
  const { isMobile } = selector.setting
  if (isMobile) {
    if (selectedKonto === null) return <></>
    return <SelectedKontoDialog konto={selectedKonto} />
  }

  return <SelectedKontoColumn konto={selectedKonto} />
})

SelectedKonto.displayName = "SelectedKonto"
