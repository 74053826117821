import React from "react"

import KontoLink from "../shared/KontoLink"
import SearchKeywordLink from "./SearchKeywordLink"

type KontoReferanseLinkProps = {
  kontoer: number[]
  kontoPrefix: number
  children: React.ReactNode
}

export default function KontoReferanseLink({
  kontoer,
  kontoPrefix,
  children,
}: KontoReferanseLinkProps): React.ReactElement {
  if (kontoer.includes(kontoPrefix)) {
    return (
      <KontoLink kontonummer={kontoPrefix} searchParam={`sok=${kontoPrefix}`} key={kontoPrefix}>
        {children}
      </KontoLink>
    )
  }

  return (
    <SearchKeywordLink konto={kontoPrefix} key={kontoPrefix}>
      {children}
    </SearchKeywordLink>
  )
}
