import { organisasjonsformer } from "./organisasjonsformer"
import { momsregistrert } from "./momsregistrert"
import { isKontonummer } from "./isKontonummer"
import { getParams, UrlParams } from "./paramsStorage"

const initialParams = Object.freeze<UrlParams>({
  orgForm: "enk",
  medMoms: "medMoms",
})

export const defaultParams: UrlParams = (getParams() as UrlParams) ?? initialParams

const pathParams = [
  ...Object.values(momsregistrert),
  ...Object.values(organisasjonsformer).map((value) => value.id),
].join("|")

type PathBuilderReturnType = {
  refresh: boolean
  path: string
}

export function pathBuilder(
  { orgForm, medMoms }: UrlParams,
  kontonummer: string | undefined = undefined,
): PathBuilderReturnType {
  const hasOrg = pathParams.includes(orgForm)
  const hasMva = pathParams.includes(medMoms)
  const isKonto = isKontonummer(kontonummer)
  const orgParam = `/${hasOrg ? orgForm : defaultParams.orgForm}`
  const mvaParam = `/${hasMva ? medMoms : defaultParams.medMoms}`
  const kontoParam = isKonto && kontonummer !== undefined ? `/${kontonummer}` : ""

  const path = `${orgParam}${mvaParam}${kontoParam}`
  const refresh = !(hasMva && hasOrg) || (kontonummer !== undefined && !isKontonummer(kontonummer))

  return { refresh, path }
}
