import React from "react"
import Style from "./IngenTreff.module.css"

type IngenTreffParam = {
  sok: string
}

export default function IngenTreff({ sok }: IngenTreffParam): React.ReactElement {
  return (
    <div className={Style.ingenTreff}>
      <p>
        Ingen treff på <b>«{sok}»</b>
      </p>
    </div>
  )
}
