import React from "react"
import { Org } from "../konto/organisasjonsformer"
import { Link } from "react-router-dom"
import { useGeneratePath } from "./useGeneratePath"

type OrgLinkParam = {
  org: Org
}

export default function OrgLink({ org }: OrgLinkParam): React.ReactElement {
  const to = useGeneratePath({ orgForm: org.id })
  return (
    <Link to={to} style={{ padding: "0.5rem" }}>
      {org.navn}
    </Link>
  )
}
