export const setting = Object.freeze({
  pagination: {
    totalItemsPerPage: 7,
    totalPageRangeDisplayed: 4,
    nextLabel: "Neste",
    previousLabel: "Forrige",
  },
  breakpoints: {
    xxLarge: 1400,
    xLarge: 1200,
    large: 992,
    medium: 768,
    small: 576,
    xSmall: 400,
  },
  searchPlaceholderShort: "Søk",
  searchInputLabel: "Søk",
  article: {
    maxDescriptionLength: 180,
  },
})
