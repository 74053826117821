import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { BrukerRolles, whoAmIApi } from "./whoAmIApi"

type AuthSliceState = {
  isLoading: boolean
  isAuthenticated: boolean
  isSupport: boolean
}
const initialState: AuthSliceState = Object.freeze({
  isAuthenticated: false,
  isSupport: false,
  isLoading: false,
})

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      return { ...state, isLoading: payload }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(whoAmIApi.endpoints.fetchWhoAmI.matchFulfilled, (state, { payload }) => {
      const { rolle } = payload
      const isSupport = rolle === BrukerRolles.SUPPORT || rolle === BrukerRolles.ADMIN
      const isAuthenticated = isSupport || rolle === BrukerRolles.BRUKER
      return { isLoading: false, isAuthenticated, isSupport }
    })
  },
})

export const { setIsLoading } = authSlice.actions

export default authSlice.reducer
