import React from "react"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"

type MedMomsLinkProps = {
  preventScrollReset?: boolean
  children: React.ReactNode
  konto: number
}
export default function SearchKeywordLink({
  children,
  preventScrollReset = true,
  konto,
}: MedMomsLinkProps): React.ReactElement {
  const search = `sok=${konto}`
  const { pathname } = useLocation()
  const to = {
    pathname,
    search,
  }
  return (
    <Link preventScrollReset={preventScrollReset} to={to}>
      {children}
    </Link>
  )
}
