import React from "react"
import { Column, ColumnMDTypes, ColumnXSTypes } from "@fiken/components"
import Card from "../shared/Card"
import Styles from "./Kontogruppe.module.css"
import KontogruppeList from "./KontogruppeList"
import { KontogruppeInfo } from "../../app/apiSlice"

type KontogruppeProp = {
  kontogrupper: KontogruppeInfo[]
}

export default function Kontogruppe({ kontogrupper }: KontogruppeProp): React.ReactElement {
  return (
    <Column types={[ColumnXSTypes.TWELVE, ColumnMDTypes.SIX]}>
      <Card>
        <div className={Styles.kontoer}>
          <KontogruppeList kontogruppeInfo={kontogrupper} />
        </div>
      </Card>
    </Column>
  )
}
