import React, { Suspense } from "react"
import { Provider } from "react-redux"
import { store } from "./store"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { RoutesName } from "./RoutesName"
import KontoPage from "../features/konto/KontoPage"
import Layout from "../features/shared/Layout"
import { kontoLoader } from "../features/konto/Kontoer"
import { useAppHook } from "./useAppHook"

function AppRoutes(): React.ReactElement {
  useAppHook()

  const routes = Object.values(RoutesName).map((route) => {
    return {
      path: route,
      loader: kontoLoader,
      element: <KontoPage />,
    }
  })

  return (
    <Suspense>
      <RouterProvider router={createBrowserRouter(routes)} />
    </Suspense>
  )
}

function App(): React.ReactElement {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <Layout>
          <AppRoutes />
        </Layout>
      </Provider>
    </React.StrictMode>
  )
}

export default App
