import React from "react"
import Styles from "./Info.module.css"
import { Column, Container, ContainerTypes, Row } from "@fiken/components"
import { useAppSelector } from "../../app/hooks"
import { ReactComponent as Sok } from "../shared/Søk.svg"

export default function Info(): React.ReactElement {
  const { isMobile } = useAppSelector((state) => state.setting)

  return (
    <div className={Styles.info}>
      <Container type={ContainerTypes.LG}>
        <Row>
          <Column className={Styles.column}>
            <div>{isMobile ? <Sok /> : null}</div>
            <h2>Finner du ikke en passende konto?</h2>
            <p>
              Om du ikke finner en passende konto, så prøv å finn noe som ligner. Det aller viktigste i et regnskap er
              at totale inntekter og utgifter stemmer!
            </p>
          </Column>
        </Row>
      </Container>
    </div>
  )
}
