export type Org = {
  id: string
  navn: string
  koder: string[]
}

export const organisasjonsformer = {
  enk: { id: "enk", navn: "Enkeltpersonforetak (ENK)", koder: ["ENK"] },
  as: { id: "as", navn: "AS / NUF", koder: ["AS", "NUF"] },
  annet: { id: "annet", navn: "Annet", koder: [] },
}
