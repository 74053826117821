import React from "react"
import Styles from "./Searchbar.module.css"
import { SearchInput } from "@fiken/components"

export type SearchbarProps = {
  isFirstResponder?: boolean
  value: string
  onChange: (value: string) => void
}

export default function Searchbar(props: SearchbarProps): React.ReactElement {
  return (
    <div className={Styles.searchbar}>
      <svg width="100%" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5833 27.7083C21.8426 27.7083 26.9167 22.485 26.9167 16.0417C26.9167 9.59834 21.8426 4.375 15.5833 4.375C9.32411 4.375 4.25 9.59834 4.25 16.0417C4.25 22.485 9.32411 27.7083 15.5833 27.7083Z"
          stroke="#272727"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.75 30.625L23.5875 24.2812"
          stroke="#272727"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <SearchInput
        {...props}
        onChange={props.onChange}
        className={Styles.input}
        ariaLabel={"Søk felt"}
        placeholder={`Hva har du kjøpt (feks "PC" eller "Bilforsikring")`}
      />
    </div>
  )
}
