import React from "react"
import { generatePath, useParams } from "react-router"
import { Link } from "react-router-dom"
import { RoutesName } from "../../app/RoutesName"

type KontoLinkProps = {
  preventScrollReset?: boolean
  children: React.ReactNode
  searchParam: string
  kontonummer: number
}
export default function KontoLink({
  children,
  preventScrollReset = true,
  searchParam,
  kontonummer,
}: KontoLinkProps): React.ReactElement {
  const params = useParams()

  const useGeneratePath = (): string => {
    return generatePath(RoutesName.ORG_FORM_MED_MOMS_KONTO, {
      ...params,
      kontonummer,
    })
  }

  return (
    <Link
      preventScrollReset={preventScrollReset}
      to={{
        pathname: `/${useGeneratePath()}`,
        search: searchParam,
      }}
    >
      {children}
    </Link>
  )
}
