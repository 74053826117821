import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { setting } from "./setting"

type SettingSliceState = {
  paddingX: number
  viewportWidth: number
  clipPathHeight: number
  isMobile: boolean
}
const initialState: SettingSliceState = Object.freeze({
  viewportWidth: setting.breakpoints.xLarge,
  paddingX: 32,
  clipPathHeight: 45,
  isMobile: false,
})

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setViewportWidth: (state, { payload }: PayloadAction<number>) => {
      return {
        ...state,
        viewportWidth: payload,
        clipPathHeight: Math.min((payload * 45) / 1440, 45),
        isMobile: setting.breakpoints.medium > payload,
      }
    },

    setPaddingX: (state, { payload }: PayloadAction<number>) => {
      return { ...state, paddingX: payload }
    },
  },
})

export const { setViewportWidth, setPaddingX } = settingSlice.actions
export default settingSlice.reducer
