import React from "react"
import { matchPath, Navigate, useLocation, useParams } from "react-router"
import { RoutesName } from "../../app/RoutesName"
import { pathBuilder } from "./pathBuilder"
import { useCustomParams } from "./useCustomParams"
import KontoLayout from "../shared/KontoLayout"
import Kontoer from "./Kontoer"

export default function KontoPage(): React.ReactElement {
  const { pathname } = useLocation()
  const { konto, kontonummer } = useParams()

  const routes = RoutesName

  const { KONTO, NO_MATCH } = routes
  const currentRoute = Object.values(routes).find((pattern) => {
    return matchPath(pattern, pathname)
  })

  const parameter = useCustomParams()

  if (currentRoute === KONTO) {
    const { path } = pathBuilder(parameter, konto)
    return <Navigate to={path} />
  } else if (currentRoute === NO_MATCH) {
    const { path } = pathBuilder(parameter)
    return <Navigate to={path} />
  } else {
    const { refresh, path } = pathBuilder(parameter, kontonummer)
    if (refresh) {
      return <Navigate to={path} />
    }
  }

  return (
    <KontoLayout>
      <Kontoer />
    </KontoLayout>
  )
}
