import { useLazyFetchWhoAmIQuery, WhoAmI } from "../../app/whoAmIApi"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

export const useWhoAmIQueryOnParamsChange = (callback: (data: WhoAmI) => void): void => {
  const params = useParams()
  const [fetch] = useLazyFetchWhoAmIQuery()

  useEffect(() => {
    void fetch().unwrap().then(callback)
  }, [params])
}
