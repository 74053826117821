const loadState = (item: string): object | undefined => {
  try {
    const serializedState = localStorage.getItem(item)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const saveState = (key: string, state: object): void => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(key, serializedState)
  } catch (err) {}
}

const getFkState = (key: string): object | undefined => loadState(key)
const updateFkState = (key: string, state: object): void => {
  const fkState = loadState(key) ?? {}
  saveState(key, { ...fkState, ...state })
}

export const getItem = (key: string): object | undefined => {
  return getFkState(key)
}
export const setItem = (key: string, state: object): void => {
  return updateFkState(key, state ?? {})
}
