import React from "react"
import { BaseViewProp } from "../../types/type"
import KontoHead from "../head/KontoHead"
import { LinearProgressIndicator } from "@fiken/components"
import { useAppSelector } from "../../app/hooks"
import { QueryStatus } from "@reduxjs/toolkit/query"
import { useGMTDataLayer } from "../tracking/useGMTDataLayer"
import { useWhoAmIQueryOnParamsChange } from "../tracking/useWhoAmIQueryOnParamsChange"
import { BrukerRolles, WhoAmI } from "../../app/whoAmIApi"

export type LayoutProp = BaseViewProp

export default function KontoLayout({ children }: LayoutProp): React.ReactElement {
  const isLoading = useAppSelector((state) => {
    return Object.values(state.api.queries).some((query) => {
      return query !== undefined && query.status === QueryStatus.pending
    })
  })

  useWhoAmIQueryOnParamsChange((data: WhoAmI) => {
    const { brukerInfo, rolle } = data
    if (brukerInfo !== undefined) {
      useGMTDataLayer({ innlogget: true, ...brukerInfo })
    } else {
      if (rolle === BrukerRolles.UKJENT) {
        useGMTDataLayer({ innlogget: false })
      }
    }
  })

  return (
    <>
      <KontoHead />
      <LinearProgressIndicator isLoading={isLoading} />
      {children}
    </>
  )
}
