import React, { useEffect, useMemo, useState } from "react"
import { generatePath, useNavigate, useParams } from "react-router"
import { apiSlice, KontogruppeInfo, useFetchKontogrupperQuery } from "../../app/apiSlice"
import { Container, ContainerTypes, isNumber, keyboardEventPropertyValues, Row } from "@fiken/components"
import { SelectedKonto } from "../selected-konto/SelectedKonto"
import Kontogruppe from "../kontogruppe/Kontogruppe"
import Filter from "../filter/Filter"
import { useDispatch } from "react-redux"
import { resetSelectedKonto } from "./kontoSlice"
import Search from "../search/Search"
import { useSearchParams } from "react-router-dom"
import { store } from "../../app/store"
import { organisasjonsformer } from "./organisasjonsformer"
import { RoutesName } from "../../app/RoutesName"

export type KontoForFuse = {
  kontogruppeInfo: KontogruppeInfo[]
  sokord: string[]
}

const { enk, as } = organisasjonsformer

const finnKoder = (orgForm: string): string[] => {
  const org = Object.values(organisasjonsformer).find((f) => f.id === orgForm)
  return org === undefined ? [] : org.koder
}

const buildKontoForFuse = (kontogruppeInfo: KontogruppeInfo[]): KontoForFuse => {
  return {
    kontogruppeInfo,
    sokord: kontogruppeInfo.flatMap(({ kontoer }) => {
      return kontoer.flatMap((k) => k.metaData.sokeord.map((m) => m.toLowerCase()))
    }),
  }
}

const filterKontoForOrg = (info: KontogruppeInfo[], orgForm: string): KontogruppeInfo[] => {
  return info
    .map((konto) => {
      const koder = finnKoder(orgForm)
      return {
        ...konto,
        kontoer: konto.kontoer.filter(({ kunForOrgForm }) => {
          return koder.every((org) => kunForOrgForm.includes(org))
        }),
      }
    })
    .filter(({ kontoer }) => kontoer.length > 0)
}

export const kontoLoader = async (): Promise<any> => {
  const request = store.dispatch(apiSlice.endpoints.fetchKontogrupper.initiate())

  request.unsubscribe()
  return await request
}

export default function Kontoer(): React.ReactElement {
  const params = useParams()
  const { kontonummer, orgForm } = params
  const [searchParam] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data } = useFetchKontogrupperQuery()
  const { ArrowDown, ArrowUp } = keyboardEventPropertyValues.code

  const kontoForFuse = useMemo(() => {
    if (data !== undefined) {
      if (orgForm === enk.id || orgForm === as.id) {
        return buildKontoForFuse(filterKontoForOrg(data, orgForm))
      } else {
        return buildKontoForFuse(data)
      }
    }
  }, [orgForm])

  const [kontogrupper, setKontogrupper] = useState<KontogruppeInfo[]>([])

  const setData = (): void => {
    const keyword = searchParam.toString()
    if (kontoForFuse !== undefined && keyword.length === 0) {
      setKontogrupper(kontoForFuse.kontogruppeInfo)
    }
  }

  useEffect(setData, [kontoForFuse])

  useEffect(() => {
    if (kontonummer === undefined) {
      dispatch(resetSelectedKonto())
    }
  }, [kontonummer])

  const useGeneratePath = (kr: number): string => {
    return generatePath(RoutesName.ORG_FORM_MED_MOMS_KONTO, {
      ...params,
      kontonummer: kr,
    })
  }

  const onKeydown = ({ code }: React.KeyboardEvent<HTMLDivElement>): void => {
    if (code === ArrowUp || code === ArrowDown) {
      const flatKonto = kontogrupper.flatMap((kontogrupper) => kontogrupper.kontoer)
      if (kontonummer !== undefined && isNumber(kontonummer)) {
        const index = flatKonto.findIndex((konto) => konto.kontonummer === parseInt(kontonummer))
        const konto =
          code === ArrowDown
            ? index === flatKonto.length - 1
              ? flatKonto[0]
              : flatKonto[index + 1]
            : index === 0
            ? flatKonto[0]
            : flatKonto[index - 1]
        if (konto !== undefined) {
          navigate(`/${useGeneratePath(konto.kontonummer)}?${searchParam.toString()}`)
        }
      } else {
        navigate(`/${useGeneratePath(flatKonto[0].kontonummer)}?${searchParam.toString()}`)
      }
    }
  }

  return (
    <div onKeyDown={onKeydown}>
      <Container type={ContainerTypes.XL}>
        <Row>
          {kontoForFuse !== undefined ? (
            <Filter>
              <Search reset={setData} onSearch={setKontogrupper} kontoForFuse={kontoForFuse} />
            </Filter>
          ) : null}
        </Row>
        <Row>
          <Kontogruppe kontogrupper={kontogrupper} />
          <SelectedKonto />
        </Row>
      </Container>
    </div>
  )
}
