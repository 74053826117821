import React from "react"
import { KontogruppeInfo } from "../../app/apiSlice"
import KontoerList from "./KontoerList"

type KontogruppeListItemParam = {
  kontogruppeInfo: KontogruppeInfo
}

export function KontogruppeListItem({ kontogruppeInfo }: KontogruppeListItemParam): React.ReactElement {
  const { nummer, navn, kontoer } = kontogruppeInfo
  return (
    <li>
      <dl>
        <dt>{nummer}</dt>
        <dd>{navn}</dd>
      </dl>
      <KontoerList kontoer={kontoer} />
    </li>
  )
}
