import React from "react"
import ReactCommonmark from "react-commonmark"

import { kontoReferanser } from "./kontoReferanser"
import PropTypes from "prop-types"
import { organisasjonsformer } from "../konto/organisasjonsformer"
import { momsregistrert } from "../konto/momsregistrert"

const { as, enk } = organisasjonsformer

const tags = {
  mva: ({ medMoms }) => medMoms === momsregistrert.medMoms,
  as: ({ orgForm }) => orgForm === as.id,
  enk: ({ orgForm }) => orgForm === enk.id,
  forever: () => false,
  fiken: () => false,
  kontohjelper: () => true,
}

export const skalVises = ({ tagName, ...props }) => {
  const ikkePattern = /^ikke-/
  if (ikkePattern.test(tagName)) {
    return !tags[tagName.replace(ikkePattern, "")](props)
  }
  return tags[tagName](props)
}

const tagNames = Object.keys(tags).flatMap((tag) => [tag, `ikke-${tag}`])

const formaterTall = (tekst) => {
  const tall = Number.parseFloat(tekst.replace(/,/g, ".").replace(/\s/g, ""))
  if (isNaN(tall)) {
    return tekst
  }
  if (tall >= 2014 && tall <= new Date().getFullYear() + 1) {
    return tekst
  }
  return " " + tall.toLocaleString("nb-NO").replace(/\s/g, "\u00A0")
}

const postProcessText = (element) => {
  if (typeof element !== "string") {
    return element
  }
  return element
    .replace(/(kr\.?)\s(\d)/g, "$1\u00A0$2")
    .replace(/(\d)\s(%|kr)/g, "$1\u00A0$2")
    .replace(/(\s)(\d+[, ]?)+\d/g, formaterTall)
}

const preProcessText = (tekst, skalVises) => {
  if (!tekst) {
    return undefined
  }

  let commonmarkKilde = tekst
  tagNames.forEach((tagName) => {
    const matcher = new RegExp(`<${tagName}>([\\s\\S]*?)</${tagName}>`)
    let match

    while ((match = matcher.exec(commonmarkKilde)) !== null) {
      commonmarkKilde = commonmarkKilde.replace(match[0], skalVises(tagName) ? match[1] : "")
    }
  })

  return commonmarkKilde
}

export default function Hjelpetekst({ hjelpetekst, kontonumre, medMoms, orgForm }) {
  const vises = (tagName) =>
    skalVises({
      tagName,
      medMoms,
      orgForm,
    })
  if (hjelpetekst) {
    return (
      <ReactCommonmark
        source={preProcessText(hjelpetekst, vises)}
        renderers={{
          text: ({ literal }) => <>{kontoReferanser(kontonumre, literal).map(postProcessText)}</>,
        }}
      />
    )
  }

  return <></>
}

Hjelpetekst.propTypes = {
  children: PropTypes.any,
  kontonumre: PropTypes.any,
  orgForm: PropTypes.string,
  medMoms: PropTypes.string,
  hjelpetekst: PropTypes.string,
}
