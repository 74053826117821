import { useAppDispatch } from "./hooks"
import { useEffect } from "react"
import { setViewportWidth } from "./settingSlice"

export const useAppHook = (): void => {
  const dispatch = useAppDispatch()

  const setGlobalSetting = (): void => {
    const width = window.innerWidth
    dispatch(setViewportWidth(width))
  }

  useEffect(() => {
    setGlobalSetting()
    window.addEventListener("resize", setGlobalSetting)
    return () => window.removeEventListener("resize", setGlobalSetting)
  }, [])
}
