import React from "react"
import { ClipPathEdges, Column, Container, ContainerTypes, CurvedClipPath, Row } from "@fiken/components"
import Styles from "./Main.module.css"
import { ReactComponent as Sok } from "../shared/Søk.svg"
import { useAppSelector } from "../../app/hooks"

export default function Main(): React.ReactElement {
  const { clipPathHeight, isMobile } = useAppSelector((state) => state.setting)
  return (
    <main className={Styles.main}>
      <CurvedClipPath edge={ClipPathEdges.BOTTOM} curveHeight={clipPathHeight}>
        <div>
          <Container type={ContainerTypes.XL}>
            <Row>
              <Column className={Styles.column}>
                <div>
                  <h1>Gratis kontohjelp til regnskapet</h1>
                  <p>
                    Søk på helt vanlige ord, slik som kontorstol eller macbook, og vi forteller deg hvilken konto det
                    skal føres på i regnskapet – helt gratis!
                  </p>
                </div>
                <div>{isMobile ? null : <Sok />}</div>
              </Column>
            </Row>
          </Container>
        </div>
      </CurvedClipPath>
    </main>
  )
}
